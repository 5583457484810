import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicRoute from "./Utilities/publicRouting";
import { ScrollToTop } from "./Components/CommonComponents/ScrollToTop";

// Lazy load components
const AboutUs = lazy(() => import("./Components/AboutUs"));
const ContactUs = lazy(() => import("./Components/ContactUs"));
const EstimationFees = lazy(() => import("./Components/EstimationFees"));
const Home = lazy(() => import("./Components/Home"));
const Payments = lazy(() => import("./Components/Payments"));
const Services = lazy(() => import("./Components/Services"));
const Testimonials = lazy(() => import("./Components/Testimonials"));
const WorkSample = lazy(() => import("./Components/WorkSample/Index"));
const CostEstimation = lazy(() =>
  import("./Components/CostEstimation/CostEstimation")
);
const Material_TakeOff = lazy(() =>
  import("./Components/Material_TakeOff/Material_TakeOff")
);
const Sheduling = lazy(() => import("./Components/Sheduling/Sheduling"));
const Planning = lazy(() => import("./Components/Planning/Planning"));
const GeneralRequirements = lazy(() =>
  import("./Components/GeneralRequirements/GeneralRequirements")
);
const SiteConstruction = lazy(() =>
  import("./Components/SiteConstruction/SiteConstruction")
);
const ThermalProtection = lazy(() =>
  import("./Components/ThermalProtection/ThermalProtection")
);
const Masonry = lazy(() =>
  import("./Components/CommonComponents/Masonry/Masonry")
);
const ConcreteEstimation = lazy(() =>
  import("./Components/ConcreteEstimation/ConcreteEstimation")
);
const MetalEstimations = lazy(() =>
  import("./Components/MetalsEstimations/MetalEstimations")
);
const WooDandPlastic = lazy(() =>
  import("./Components/Wood & Plastic/WooD&Plastic")
);
const DoorsandWindows = lazy(() =>
  import("./Components/DoorsandWindows/DoorsandWindows")
);
const Finishes = lazy(() => import("./Components/Finishes/Finishes"));
const ConveyingSystem = lazy(() =>
  import("./Components/ConveyingSystem/ConveyingSystem")
);
const Equipment = lazy(() => import("./Components/Equipment/Equipment"));
const Furnishings = lazy(() => import("./Components/Furnishings/Furnishings"));
const Electrical = lazy(() => import("./Components/Electrical/Electrical"));
const Mechanical = lazy(() => import("./Components/Mechanical/Mechanical"));
const Plumbing = lazy(() => import("./Components/Plumbing/Plumbing"));
const HVAC = lazy(() => import("./Components/HVAC/HVAC"));
const SpecialConstruction = lazy(() =>
  import("./Components/SpecialConstruction/SpecialConstruction")
);
const Specialities = lazy(() =>
  import("./Components/Specialities/Specialities")
);
const PageNotFound = lazy(() => import("./Components/PageNotFound"));

function LoadingSpinner() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="spinner-border text-dark" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/services/" element={<Services />} />
            <Route path="/contact-us/" element={<ContactUs />} />
            <Route path="/testimonials/" element={<Testimonials />} />
            <Route path="/estimation-fee/" element={<EstimationFees />} />
            <Route path="/work-samples/" element={<WorkSample />} />
            <Route path="/about-us/" element={<AboutUs />} />
            <Route path="/payment/" element={<Payments />} />
            <Route
              path="/cost-estimating-services/"
              element={<CostEstimation />}
            />
            <Route
              path="/construction-takeoff-services/"
              element={<Material_TakeOff />}
            />
            <Route path="/cost-scheduling/" element={<Sheduling />} />
            <Route path="/planning/" element={<Planning />} />
            <Route
              path="/general-requirements/"
              element={<GeneralRequirements />}
            />
            <Route
              path="/sitework-estimating-services/"
              element={<SiteConstruction />}
            />
            <Route
              path="/thermal-moisture-protection-estimating-services/"
              element={<ThermalProtection />}
            />
            <Route path="/masonry-estimating-services/" element={<Masonry />} />
            <Route
              path="/concrete-estimating-services/"
              element={<ConcreteEstimation />}
            />
            <Route
              path="/metals-estimating-services/"
              element={<MetalEstimations />}
            />
            <Route
              path="/wood-plastic-estimating-services/"
              element={<WooDandPlastic />}
            />
            <Route
              path="/door-window-estimating-services/"
              element={<DoorsandWindows />}
            />
            <Route path="/interior-exterior-finishes/" element={<Finishes />} />
            <Route path="/conveying-systems/" element={<ConveyingSystem />} />
            <Route
              path="/equipment-estimating-services/"
              element={<Equipment />}
            />
            <Route
              path="/furnishing-estimating-services/"
              element={<Furnishings />}
            />
            <Route
              path="/electrical-estimating-services/"
              element={<Electrical />}
            />
            <Route
              path="/mechanical-estimating-services/"
              element={<Mechanical />}
            />
            <Route
              path="/plumbing-estimating-services/"
              element={<Plumbing />}
            />
            <Route path="/hvac-estimating-services/" element={<HVAC />} />
            <Route
              path="/special-construction-estimating-services/"
              element={<SpecialConstruction />}
            />
            <Route path="/specialities/" element={<Specialities />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
