import whatsapp from "./whatsapp.svg";
import facebook from "./fb.svg";
import email from "./email.svg";
import insta from "./insta.svg";
import linkedIn from "./LinkedIn.svg";
import phone from "./phone.svg";
import logo from "./logo.svg";
import home1Image from "./Home1Image.svg";
import service1 from "./Services1.svg";
import service2 from "./Services2.svg";
import service3 from "./Services3.svg";
import service4 from "./Services4.svg";
import service5 from "./Services5.svg";
import service6 from "./Services6.svg";
import service7 from "./Services7.svg";
import service8 from "./Services8.svg";
import locationSymbol from "./Location.svg";
import phoneSymbol from "./phoneSymbol.svg";
import emailSymbol from "./emaiSymbol.svg";
import facebookBlack from "./facebookBlack.svg";
import instaBlack from "./instagramBlack.svg";
import linkedInBlack from "./linkedInBlack.svg";
import whatsappBlack from "./whatsappBlack.svg";
import dropbox from "./dropbox.svg";
import googleDrive from "./googleDrive.svg";
import payment1 from "./payment1.svg";
import payment2 from "./payment2.svg";
import payment3 from "./payment3.svg";
import payment4 from "./payment4.svg";
import payment5 from "./payment5.svg";
import payment6 from "./payment6.svg";
import payment7 from "./payment7.svg";
import fee from "./fee.svg";
import tick from "./greybg-round-tick.png";
import humanIcon from "./humanIcon.svg";
import servicesImg from "../Assests/ServicesImages/ServiceImage.svg";
import servicesImg1 from "../Assests/ServicesImages/ServiceImage1.svg";
import servicesImg2 from "../Assests/ServicesImages/ServiceImage2.svg";
import servicesImg3 from "../Assests/ServicesImages/ServiceImage3.svg";
import servicesImg4 from "../Assests/ServicesImages/ServiceImage4.svg";
import servicesImg5 from "../Assests/ServicesImages/ServiceImage5.svg";
import servicesImg6 from "../Assests/ServicesImages/ServiceImage6.svg";
import servicesImg7 from "../Assests/ServicesImages/ServiceImage7.svg";
import servicesImg8 from "../Assests/ServicesImages/ServiceImage8.svg";
import servicesImg9 from "../Assests/ServicesImages/ServiceImage9.svg";
import servicesImg10 from "../Assests/ServicesImages/ServiceImage10.svg";
import servicesImg11 from "../Assests/ServicesImages/ServiceImage11.svg";
import servicesImg12 from "../Assests/ServicesImages/ServiceImage12.svg";
import servicesImg13 from "../Assests/ServicesImages/ServiceImage13.svg";
import servicesImg14 from "../Assests/ServicesImages/ServiceImage14.svg";
import servicesImg15 from "../Assests/ServicesImages/ServiceImage15.svg";
import servicesImg16 from "../Assests/ServicesImages/ServiceImage16.svg";
import servicesImg17 from "../Assests/ServicesImages/ServiceImage18.svg";
import servicesImg18 from "../Assests/ServicesImages/ServiceImage17.svg";
import servicesImg19 from "../Assests/ServicesImages/ServiceImage19.svg";
import servicesImg20 from "../Assests/ServicesImages/ServiceImage20.svg";
import AboutUs from "../Assests/AboutUs.png";

export const Images = {
  facebookBlack,
  whatsappBlack,
  linkedInBlack,
  instaBlack,
  whatsapp,
  email,
  facebook,
  insta,
  linkedIn,
  phone,
  logo,
  home1Image,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  service7,
  service8,
  locationSymbol,
  phoneSymbol,
  emailSymbol,
  dropbox,
  googleDrive,
  servicesImg,
  servicesImg1,
  servicesImg2,
  servicesImg3,
  servicesImg4,
  servicesImg5,
  servicesImg6,
  servicesImg7,
  servicesImg8,
  servicesImg9,
  servicesImg10,
  servicesImg11,
  servicesImg12,
  servicesImg13,
  servicesImg14,
  servicesImg15,
  servicesImg16,
  servicesImg17,
  servicesImg18,
  servicesImg19,
  servicesImg20,
  tick,
  fee,
  humanIcon,
  payment1,
  payment2,
  payment3,
  payment4,
  payment5,
  payment6,
  payment7,
  AboutUs,
};
